import React, { Suspense, useEffect, useState } from 'react';
import { Col, Container, Image, Nav, Navbar, Row } from 'react-bootstrap';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Redirect, Route, useLocation, Switch, Link, useRouteMatch } from 'react-router-dom';
import { setPageTitle } from '../src/store/actions';
import ErrorFallback from './components/ErrorFallback';
import Loader from './components/Loader';
import PageNotFound from './components/PageNotFound';
import { brokers } from './config/brokers';
import { strings } from './localizations/strings';

const companyLogo = require('./assets/images/CP_logo_black.png').default;

const OrderBook = React.lazy(() => import('./views/orderBook/index'));
const OrderBookV1 = React.lazy(() => import('./views/orderBookV1/index'));
const MinimalOrders = React.lazy(() => import('./views/orderBookV1/MinimalOrders'));

export default function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageTitle('Welcome'));
  }, [dispatch]);

  return (
    <BrowserRouter history={history}>
      <Suspense fallback={<Loader show={true} />}>
        <Switch>
          <Route exact sensitive path='/mp/orders' component={MinimalOrders} />
          <Route sensitive path='/' component={Home} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

function Home () {
  return (
    <div id="content-wrapper">
      <NavigationBar />

      <Container fluid id='content'>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Switch>
            <Route exact sensitive path='/' render={() => <Redirect to={'/orders'} />} />
            <Route exact sensitive path='/orders' component={OrderBookV1} />

            <Route exact sensitive path='/btc_jpy' render={props => <OrderBook symbol='btc_jpy' {...props} />} />
            <Route exact sensitive path='/eth_jpy' render={props => <OrderBook symbol='eth_jpy' {...props} />} />
            <Route exact sensitive path='/xrp_jpy' render={props => <OrderBook symbol='xrp_jpy' {...props} />} />

            <Route exact sensitive path='/pageNotFound' component={PageNotFound} />
            <Route render={() => <Redirect to='/pageNotFound' />} />
          </Switch>
        </ErrorBoundary>
      </Container>

      <Footer />
    </div>
  );
}

function NavigationBar () {
  const location = useLocation();
  const match = useRouteMatch();

  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    let path = location.pathname.split('/').filter(el => el !== '');
    setActiveTab(path[0]);
  }, [location.pathname]);

  return (
    <Navbar collapseOnSelect expand='sm' bg='white' sticky='top'>
      <Container>
        <Navbar.Brand href='https://modalpoint.jp/testtest/'>
          <Image src={companyLogo} />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls='responsive-navbar-nav' />

        <Navbar.Collapse id='responsive-navbar-nav'>
            
          <Nav activeKey={activeTab} onSelect={(key) => setActiveTab(key)}>
              <Nav.Link as={Link} to={match.url + 'orders'} eventKey={'orders'}>
                {strings.order_book}
              </Nav.Link>

              <Nav.Link as={Link} to={match.url + 'btc_jpy'} eventKey={'btc_jpy'}>
                <Image src={require(`./assets/images/logo-btc.png`).default} />BTC
              </Nav.Link>
              <Nav.Link as={Link} to={match.url + 'eth_jpy'} eventKey={'eth_jpy'}>
                <Image src={require(`./assets/images/logo-eth.png`).default} />ETH
              </Nav.Link>
              <Nav.Link as={Link} to={match.url + 'xrp_jpy'} eventKey={'xrp_jpy'}>
                <Image src={require(`./assets/images/logo-xrp.png`).default} />XRP
              </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

function Footer () {
  return (
    <Container fluid className='footer py-3'>
      <Row>
        <Col>
          <small>
            ⓒ 2021&nbsp;
            <a href='#' target='_blank'>
              {strings.company_name}
            </a>
            . {strings.rights_reserved}
          </small>
        </Col>
      </Row>
    </Container>
  );
}