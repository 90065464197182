import { siteConstants } from '../constants';

const initialState = {};

export function site (state = initialState, action) {
    switch (action.type) {
        /*
         *  Set page title
         */
        case siteConstants.SET_PAGE_TITLE:
            return { ...state, title: action.payload };

        default:
            return state;
    }
}