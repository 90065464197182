import { combineReducers } from 'redux';
import { site } from './site';
import { orderbook } from './orderbook';

const appReducer = combineReducers ({
    orderbook
    , site
});

const rootReducer = (state, action) => {
    return appReducer(state, action);
}

export default rootReducer;