import pako from "pako";

export const brokers = [
    {
        name: 'BitFlyer'
        , code: 'bf'
        , icon: 'logo-bf.png'
        , image: 'bitflyer_logo.png'
        , page_url: 'https://bitflyer.com/ja-jp/login'
        , connection: {
            type: 'socket_io'
            , endpoint: 'https://io.lightstream.bitflyer.com'
            , options: {
                transports: ['websocket']
                , maxHttpBufferSize: 1e8
                , pingTimeout: 60000
            }
        }
        , order_book: {
            channels: ['lightning_board_snapshot_BTC_JPY'] //, 'lightning_board_BTC_JPY'
            , response: (res) => res
        }
        , large_trades: {
            channels: ['lightning_executions_BTC_JPY']
            , response: (res) => {
                let temp = [];

                res.map(el => {
                    let obj = {}, timestamp = null;

                    if (el.exec_date) {
                        let d = new Date(el.exec_date);
                        timestamp = d.getTime();
                    }
                
                    obj.id = el.id;
                    obj.side = el.side.toLowerCase();
                    obj.price = el.price;
                    obj.size = el.size;
                    obj.timestamp = timestamp

                    temp.push({ ...obj });
                })

                return temp;
            }
        }
        , ticker: {
            channels: ['lightning_ticker_FX_BTC_JPY']
            , response: (res) => {
                let temp = {};

                temp.ask = res.best_ask;
                temp.bid = res.best_bid;
                temp.volume = parseFloat(res.volume.toFixed(2));

                return temp;
            }
        }
    }
    , {
        name: 'CoinCheck'
        , code: 'cc'
        , icon: 'logo-coincheck.png'
        , image: 'coincheck_logo.png'
        , page_url: 'https://coincheck.com/sessions/signin'
        , connection: {
            type: 'websocket'
            , endpoint: 'wss://ws-api.coincheck.com/'
        }
        , order_book: {
            message: {'type': 'subscribe', 'channel': 'btc_jpy-orderbook'}
            , response: (res) => {
                // console.log(res)
                return res[1]
            }
        }
        , large_trades: {
            message: {'type': 'subscribe', 'channel': 'btc_jpy-trades'}
            , response: (res) => {
                let temp = [];
                temp.push({
                    id: res[0]
                    , side: res[4]
                    , price: res[2] && parseInt(res[2])
                    , size: res[3]
                    , timestamp: Date.now()
                });

                return temp;
            }
        }
    }
    // , {
    //     name: 'Liquid Quoine'
    //     , code: 'liquid'
    //     , icon: 'logo-liquid.png'
    //     , image: 'liquid_logo.png'
    //     , page_url: 'https://app.liquid.com/sign-in'
    //     , connection: {
    //         type: 'websocket'
    //         , endpoint: 'wss://tap.liquid.com/app/LiquidTapClient'
    //     }
    //     , order_book: {
    //         message: {"event": "pusher:subscribe", "data": {"channel": "price_ladders_cash_btcjpy"}}
    //         , response: (res) => res.data && Object.keys(res.data).length !== 0 && JSON.parse(res.data)
    //     }
    //     , large_trades: {
    //         message: {"event": "pusher:subscribe", "data": {"channel": "executions_cash_btcjpy"}}
    //         , response: (res) => {
    //             let temp = [];

    //             if (res.data && Object.keys(res.data).length !== 0) {
    //                 let objData = JSON.parse(res.data);

    //                 if (Object.keys(objData).length > 2) {
    //                     temp.push({
    //                         id: objData.id
    //                         , side: objData.taker_side
    //                         , price: objData.price
    //                         , size: objData.quantity
    //                         , timestamp: objData.created_at * 1000
    //                     });
    //                 }
    //             }

    //             return temp;
    //         }
    //     }
    // }
    , {
        name: 'GMO'
        , code: 'gmo'
        , icon: 'logo-gmo.png'
        , image: 'zcomcoin_logo.png'
        , page_url: 'https://coin.z.com/jp/member/login'
        , connection: {
            type: 'websocket'
            , endpoint: 'wss://api.coin.z.com/ws/public/v1'
        }
        , order_book: {
            message: {"command": "subscribe", "channel": "orderbooks", "symbol": "BTC"}
            , response: (res) => res
        }
        , large_trades: {
            message: {"command": "subscribe", "channel": "trades", "symbol": "BTC"}
            , response: (res) => {
                let temp = [];

                temp.push({
                    id: null
                    , side: res.side
                    , price: res.price
                    , size: res.size
                    , timestamp: Date.parse(res.timestamp)
                });

                return temp;
            }
        }
        , ticker: {
            message: {"command": "subscribe", "channel": "ticker", "symbol": "BTC_JPY"}
            , response: (res) => {
                // console.log('gmo', res)
                let temp = {};
                let intVolume = parseFloat(res.volume);

                temp.ask = parseInt(res.ask);
                temp.bid = parseInt(res.bid);
                temp.volume = parseFloat(intVolume.toFixed(2));

                return temp;
            }
        }
    }
    , {
        name: 'Zaif'
        , code: 'zaif'
        , icon: 'logo-zaif.png'
        , image: 'zaif_logo.png'
        , page_url: 'https://zaif.jp/login'
        , connection: {
            type: 'websocket'
            , endpoint: 'wss://ws.zaif.jp/stream?currency_pair=btc_jpy'
        }
        , order_book: {
            message: null
            , response: (res) => res
        }
        , large_trades: {
            message: null
            , response: (res) => {
                let temp = [];

                if (res.trades) {
                    res.trades.map(el => {
                        let unixToDate = new Date(el.date * 1000);
                        let timestamp =  unixToDate.valueOf()

                        let obj = {};

                        obj.id = el.tid;
                        obj.side = el.trade_type === 'ask' ? 'sell' : el.trade_type === 'bid' ? 'buy' : null;
                        obj.price = el.price;
                        obj.size = el.amount;
                        obj.timestamp = timestamp;

                        temp.push({ ...obj });
                    });
                }
                
                return temp;
            }
        }
    }
    , {
        name: 'BitBank'
        , code: 'bb'
        , icon: 'logo-bitbank.png'
        , image: 'bitbank_logo.png'
        , page_url: 'https://app.bitbank.cc/login'
        , connection: {
            type: 'socket_io'
            , endpoint: 'wss://stream.bitbank.cc'
            , options: {
                transports: ['websocket']
                // , maxHttpBufferSize: 1e8
                // , pingTimeout: 60000
            }
        }        
        , order_book: {
            channels: ['depth_whole_btc_jpy', 'depth_diff_btc_jpy']
            , response: (res) => {
                let temp = {};
                temp.channel = res?.room_name;
                
                if (res?.room_name === 'depth_whole_btc_jpy') {
                    temp.asks = res?.message?.data?.asks;
                    temp.bids = res?.message?.data?.bids;
                } else if (res?.room_name === 'depth_diff_btc_jpy') {
                    temp.asks = res?.message?.data?.a;
                    temp.bids = res?.message?.data?.b;
                } 
                
                return temp;
            }
        }
        , large_trades: {
            channels: ['transactions_btc_jpy']
            , response: (res) => {
                let temp = [];

                if (res?.message?.data?.transactions) {
                    let trades = res.message.data.transactions;

                    trades.map(el => {
                        let obj = {};

                        obj.id = el.transaction_id;
                        obj.side = el.side;
                        obj.price = el.price;
                        obj.size = el.amount;
                        obj.timestamp = el.executed_at;

                        temp.push({ ...obj });
                    });
                }

                return temp;
            }
        }
    }
    , {
        name: 'BtcBox'
        , code: 'btcbox'
        , icon: 'logo-btcbox.png'
        , image: 'btcbox_logo.png'
        , page_url: 'https://www.btcbox.co.jp/user/login/'
        , connection: {
            type: 'http'
            , endpoint: ''
        }
        , order_book: {
            route: '/api/btcbox/getDepth'
            , response: (res) => res.data
        }
        , large_trades: {
            route: '/api/btcbox/getOrders'
        }
    }
    , {
        name: 'Huobi'
        , code: 'huobi'
        , icon: 'logo-huobi.png'
        , image: 'huobi_logo.png'
        , page_url: 'https://www.huobi.co.jp/login/'
        , connection: {
            type: 'websocket'
            , endpoint: 'wss://api-cloud.huobi.co.jp/ws'
            , binary_type: 'arraybuffer'
        }
        , order_book: {
            message: {"sub": "market.btcjpy.depth.step0", "id": "id1"}
            , response: (res) => {
                let data = pako.inflate(res);
                let strData	= String.fromCharCode.apply(null, new Uint16Array(data));
                let objData= JSON.parse(strData);
                // console.log('huobi >>>', objData)
                return objData;
            }
        }
        , large_trades: {
            message: {"sub": "market.btcjpy.trade.detail", "id": "id1"}
            , response: (res) => {
                let data = pako.inflate(res);
                let strData	= String.fromCharCode.apply(null, new Uint16Array(data));
                let objData= JSON.parse(strData);
                let temp = [];

                if (objData?.tick?.data) {
                    let trades = objData.tick.data;

                    trades.map(el => {
                        let obj = {};

                        obj.price = el.price;
                        obj.size = el.amount;
                        obj.side = el.direction;
                        obj.id = el.tradeId;
                        obj.timestamp = el.ts;

                        temp.push({ ...obj });
                    });
                }

                if (objData?.ping) {
                    temp.push({ ...objData });
                }

                return temp;
            }
        }
    }
    , {
        name: 'OKEX'
        , code: 'okex'
        , icon: 'logo-okex.png'
        , image: 'okcoin_logo.png'
        , page_url: 'https://www.okcoin.jp/account/login'
        , connection: {
            type: 'websocket'
            , endpoint: 'wss://connect.okcoin.jp:443/ws/v3'
            , binary_type: 'arraybuffer'
        }
        , order_book: {
            message: {"op": "subscribe", "args": ["spot/depth:BTC-JPY"]}
            , response: (res) => {
                let data = pako.inflate(res, { raw: true });
                let strData	= String.fromCharCode.apply(null, new Uint16Array(data));
                let objData= JSON.parse(strData);

                let temp = {};

                if (objData?.data && objData?.data.length !== 0) {
                    ['bids', 'asks'].map(type => {
                        if (objData.data?.[0]?.[type] && objData.data[0][type].length !== 0) {
                            let data = objData.data[0][type].map(el => ({ price: el[0], size: el[1] }));
                            temp[type] = data;
                        }
                    });

                    temp.checksum = objData.data[0].checksum;
                    temp.action = objData.action;
                }
                
                if (Object.keys(temp).length === 0) {
                    return null;
                }

                return temp;
            }
        }
        , large_trades: {
            message: {"op": "subscribe", "args": ["spot/trade:BTC-JPY"]}
            , response: (res) => {
                let data = pako.inflate(res, { raw: true });
                let strData	= String.fromCharCode.apply(null, new Uint16Array(data));
                let objData = {};

                try {
                    if (JSON.parse(strData))
                    objData = JSON.parse(strData);

                    let temp = JSON.parse(strData);
                    let trades = [];

                    if (temp.data) {
                        temp.data.map(el => {
                            let obj = {};

                            obj.id = el.trade_id;
                            obj.side = el.side;
                            obj.price = el.price;
                            obj.size = el.size;
                            obj.timestamp = Date.parse(el.timestamp);

                            trades.push({ ...obj });
                        });
                    }

                    return trades;
                } catch {
                    return null;
                }
            }
        }
    }
]

/* CoinCheck API */
// {
// name: 'CoinCheck'
// , code: 'cc'
// , icon: 'logo-coincheck.png'
// , image: 'coincheck_logo.png'
// , page_url: 'https://coincheck.com/sessions/signin'
// , connection: {
//     type: 'http'
//     , endpoint: ''
// }
// , order_book: {
//     route: '/api/coincheck/getDepth'
//     , response: (res) => res[1]
// }
// , large_trades: {
//     route: '/api/btcbox/getOrders'
// }