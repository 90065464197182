import { orderbookConstants } from '../constants';

const initialState = {
    rawOrders: {}
    , v1Orders: {}
    , largeTrades: []
    , tickers: {}
    , orders: {}
};

export function orderbook (state = initialState, action) {
    switch (action.type) {
        /*
         *  get order book
         */
        case orderbookConstants.GET_RAW_ORDERS_REQUEST:
            return { ...state, isFetching: true };

        case orderbookConstants.GET_RAW_ORDERS_SUCCESS:
            return { 
                ...state
                , isFetching: false
                , rawOrders: {...state.rawOrders, ...action.payload }
                , getRawOrdersErr: null 
            };

        case orderbookConstants.GET_RAW_ORDERS_FAILURE:
            return { ...state, isFetching: false, getRawOrdersErr: action.error };

        /*
         *  reset raw orders
         */
        case orderbookConstants.RESET_RAW_ORDERS:
            return { ...state, isFetching: false, rawOrders: {} };

        /*
         *  get large trades
         */
        case orderbookConstants.GET_LARGE_TRADES_REQUEST:
            return { ...state, isFetching: true };

        case orderbookConstants.GET_LARGE_TRADES_SUCCESS:
            return { 
                ...state
                , isFetching: false
                , largeTrades: [{ ...action.payload }, ...state.largeTrades].slice(0, 100)
                // , largeTrades: { ...action.payload }
                , getLargeTradesErr: null 
            };

        case orderbookConstants.GET_LARGE_TRADES_FAILURE:
            return { ...state, isFetching: false, getLargeTradesErr: action.error };

        /*
         *  get ticker
         */
        case orderbookConstants.GET_TICKER_REQUEST:
            return { ...state, isFetching: true };

        case orderbookConstants.GET_TICKER_SUCCESS:
            return { 
                ...state
                , isFetching: false
                , tickers: {...state.tickers, ...action.payload }
                , getTickersErr: null 
            };

        case orderbookConstants.GET_TICKER_FAILURE:
            return { ...state, isFetching: false, getTickersErr: action.error };

        /*
         *  Lowest bid
         */
        case orderbookConstants.SET_LOWEST_BID:
            return { ...state, lowestBid: action.payload };

        /*
         *  Lowest ask
         */
        case orderbookConstants.SET_LOWEST_ASK:
            return { ...state, lowestAsk: action.payload };

        /*
         *  Arb level
         */
        case orderbookConstants.SET_ARB_LEVEL:
            return { ...state, arbLevel: action.payload };

        /*
         *  Okex data
         */
        case orderbookConstants.SET_OKEX_DATA:
            return { ...state, okexPayload: action.payload };

        /*
         *  Coincheck data
         */
        case orderbookConstants.SET_COINCHECK_DATA:
            return { ...state, ccPayload: action.payload };

        /*
         *  Bitbank data
         */
        case orderbookConstants.SET_BITBANK_DATA:
            return { ...state, bbPayload: action.payload };

        /*
         *  get order book
         */
        case orderbookConstants.GET_V1_ORDERS_REQUEST:
            return { ...state, isFetching: true };

        case orderbookConstants.GET_V1_ORDERS_SUCCESS:
            return { 
                ...state
                , isFetching: false
                , v1Orders: {...state.v1Orders, ...action.payload }
                , getV1OrdersErr: null 
            };

        case orderbookConstants.GET_V1_ORDERS_FAILURE:
            return { ...state, isFetching: false, getV1OrdersErr: action.error };

        /*
         *  Orders - filtered
         */
        case orderbookConstants.SET_ORDERS:
            return { ...state, orders: action.payload };

        default:
            return state;
    }
}