import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { strings } from '../localizations/strings';

export default function ErrorFallback () {
  return (
    <Container fluid className='error-page'>
      <Row className='error-description'>
        <Col>
          <Row>
            <Col>
              <h1 className='font-weight-bold mb-3'>{strings.error_title}</h1>
              <p>{strings.error_desc}</p>
            </Col>
          </Row>
          <Row className='mt-4'>
            <Col>
              <Button variant='primary' onClick={() => window.location.reload()}>
                {strings.error_confirm_btn}
              </Button> 
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}