import LocalizedStrings from 'react-localization';

export const strings = new LocalizedStrings({
  en: {
    company_name: "Coin Point"
    , order_book: "Order Book"

    // footer
    , rights_reserved: 'All rights reserved.'
    

    // loader
    , loading: 'Loading...'
    
    // errors
    , error_title: "Oops!"
    , error_desc: "Something went wrong! We'll try to fix it soon."
    , error_confirm_btn: "Refresh"
    , not_found_title: "Oops!"
    , not_found_desc: "We can't seem to find the page you're looking for."
    , not_found_confirm_btn: "Go Home Now"
  }
});