import { bool } from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { strings } from '../localizations/strings';

export default function Loader ({ show }) {
  return (
    <Container fluid className={`loader ${show ? 'show' : 'hide'}`}>
      <Row>
        <Col>
          <h5 className='mb-0'>
            {strings.loading}
          </h5>
        </Col>
      </Row>
    </Container>
  );
}

Loader.propTypes = {
  show: bool.isRequired
}