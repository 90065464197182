import { orderbookConstants } from '../constants';

/*
 *  get raw orders
 */
export const getRawOrders = (broker, res) => {
    return async dispatch => {
        dispatch({ type: orderbookConstants.GET_RAW_ORDERS_SUCCESS, payload: { [broker]: res } });
    }
}

/*
 *  reset raw orders
 */
export const resetRawOrders = () => {
    return async dispatch => {
        dispatch({ type: orderbookConstants.RESET_RAW_ORDERS });
    }
}

/*
 *  get large trades
 */
export const getLargeTrades = (broker, res) => {
    return async dispatch => {
        dispatch({ type: orderbookConstants.GET_LARGE_TRADES_SUCCESS, payload: { broker, ...res } });
    }
}

/*
 *  get ticker
 */
export const getTickers = (broker, res) => {
    return async dispatch => {
        dispatch({ type: orderbookConstants.GET_TICKER_SUCCESS, payload: { [broker]: res } });
    }
}

/*
 *  lowest bid
 */
export const setLowestBid = (res) => {
    return async dispatch => {
        dispatch({ type: orderbookConstants.SET_LOWEST_BID, payload: res });
    }
}

/*
 *  lowest ask
 */
export const setLowestAsk = (res) => {
    return async dispatch => {
        dispatch({ type: orderbookConstants.SET_LOWEST_ASK, payload: res });
    }
}

/*
 *  arb level
 */
export const setArbLevel = (res) => {
    return async dispatch => {
        dispatch({ type: orderbookConstants.SET_ARB_LEVEL, payload: res });
    }
}

/*
 *  okex data
 */
export const setOkexData = (res) => {
    return async dispatch => {
        dispatch({ type: orderbookConstants.SET_OKEX_DATA, payload: res });
    }
}

/*
 *  coincheck data
 */
export const setCoincheckData = (res) => {
    return async dispatch => {
        dispatch({ type: orderbookConstants.SET_COINCHECK_DATA, payload: res });
    }
}

/*
 *  bitbank data
 */
export const setBitbankData = (res) => {
    return async dispatch => {
        dispatch({ type: orderbookConstants.SET_BITBANK_DATA, payload: res });
    }
}

/*
 *  get v1 orders
 */
export const getV1Orders = (broker, res) => {
    return async dispatch => {
        dispatch({ type: orderbookConstants.GET_V1_ORDERS_SUCCESS, payload: { [broker]: res } });
    }
}

/*
 *  orders - filtered
 */
export const setOrders = (res) => {
    return async dispatch => {
        dispatch({ type: orderbookConstants.SET_ORDERS, payload: res });
    }
}