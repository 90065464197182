import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducers';

const initialState = {};

let middlewares = [thunkMiddleware];
if (process.env.NODE_ENV !== 'production') {
    const loggerMiddleware = createLogger();
    middlewares = [ ...middlewares, loggerMiddleware ]
}

export const store = createStore(rootReducer, initialState,
    applyMiddleware(...middlewares)
);