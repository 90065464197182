export const orderbookConstants = {
    GET_RAW_ORDERS_REQUEST: 'GET_RAW_ORDERS_REQUEST',
    GET_RAW_ORDERS_SUCCESS: 'GET_RAW_ORDERS_SUCCESS',
    GET_RAW_ORDERS_FAILURE: 'GET_RAW_ORDERS_FAILURE',

    RESET_RAW_ORDERS: 'RESET_RAW_ORDERS',

    GET_LARGE_TRADES_REQUEST: 'GET_LARGE_TRADES_REQUEST',
    GET_LARGE_TRADES_SUCCESS: 'GET_LARGE_TRADES_SUCCESS',
    GET_LARGE_TRADES_FAILURE: 'GET_LARGE_TRADES_FAILURE',

    GET_TICKER_REQUEST: 'GET_TICKER_REQUEST',
    GET_TICKER_SUCCESS: 'GET_TICKER_SUCCESS',
    GET_TICKER_FAILURE: 'GET_TICKER_FAILURE',

    SET_ORDER_BOOK: 'SET_ORDER_BOOK',
    GET_ORDER_BOOK: 'GET_ORDER_BOOK',

    SET_LOWEST_BID: 'SET_LOWEST_BID',
    SET_LOWEST_ASK: 'SET_LOWEST_ASK',
    SET_ARB_LEVEL: 'SET_ARB_LEVEL',

    SET_OKEX_DATA: 'SET_OKEX_DATA',
    SET_COINCHECK_DATA: 'SET_COINCHECK_DATA',
    SET_BITBANK_DATA: 'SET_BITBANK_DATA',

    GET_V1_ORDERS_REQUEST: 'GET_V1_ORDERS_REQUEST',
    GET_V1_ORDERS_SUCCESS: 'GET_V1_ORDERS_SUCCESS',
    GET_V1_ORDERS_FAILURE: 'GET_V1_ORDERS_FAILURE',

    SET_ORDERS: 'SET_ORDERS',
};